import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { SubContainer } from "./styles/containers";
import { Par } from "./styles/typography";
import { Divider } from "./styles/decoration";

const Thoughts = () => (
  <StaticQuery
    query={graphql`
      query thoughtsQuery {
        allThoughtsJson {
          edges {
            node {
              title
              text
              date
            }
          }
        }
      }
    `}
    render={data =>
      data.allThoughtsJson.edges.map(item => (
        <SubContainer key={item.node.title}>
          <Par bold blue>{item.node.title.toUpperCase()}</Par>
          <Par>{item.node.text}</Par>
          <Par date>{item.node.date}</Par>
          <Divider />
        </SubContainer>
      ))
    }
  />
);

export default Thoughts;
