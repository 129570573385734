import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { SubContainer } from "./styles/containers";
import { StyledLink } from "./styles/typography";

const Menu = props => (
  <SubContainer menu>
    <StaticQuery
      query={graphql`
        query menuQuery {
          allMenuJson {
            edges {
              node {
                title
                route
                symbol
              }
            }
          }
        }
      `}
      render={data =>
        data.allMenuJson.edges.map(item => (
          <StyledLink
            key={item.node.title}
            to={item.node.route}
            current={
              props.title.toLowerCase() === item.node.title.toLowerCase()
                ? "true"
                : null
            }
          >
            {item.node.route === "/"
              ? `${item.node.symbol}`
              : `${item.node.title}`}
          </StyledLink>
        ))
      }
    />
  </SubContainer>
);

export default Menu;
