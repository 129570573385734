import styled from "styled-components";

export const Button = styled.button`
  font-family: 'Allerta', sans-serif;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 8px;
  background-color: #f17cc5;
  cursor: pointer;
`;

export const ButtonLink = styled.a`
  font-family: 'Allerta', sans-serif;
  font-size: 1rem;
  color: white;
  border: none;
  background-color: #f17cc5;
  cursor: pointer;
  text-decoration: none;
`;
