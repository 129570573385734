import React from "react";
import { SubContainer } from "./styles/containers";
import { Par } from "./styles/typography";
import Thoughts from "./thoughts";

const Random = () => (
  <SubContainer>
    <Par italic purple>Page of my random thoughts ♨</Par>
    <Thoughts />
  </SubContainer>
);

export default Random;
