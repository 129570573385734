import React from "react";
import { SubContainer } from "./styles/containers";
import { Par } from "./styles/typography";
import ProjectList from "./projectList";

const Projects = () => (
  <SubContainer>
    <Par italic purple>Some fun projects I did ( ͡° ͜ʖ ͡° )</Par>
    <ProjectList />
  </SubContainer>
);

export default Projects;
