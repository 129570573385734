import styled from "styled-components";
import { Link } from "gatsby";

export const Title = styled.h1`
  font-family: 'Pacifico', cursive;
  font-size: 2rem;
  padding: 0;
  margin-bottom: 0.5em;
`;

export const Par = styled.p`
  font-family: 'Allerta', sans-serif;
  font-size: 1.0rem;
  ${props =>
    props.italic ? `font-style: italic;` : ``}
  ${props => props.bold ? `font-weight: bold;` : ``}
  ${props => (props.blue ? `color: #6398ed;` : ``)}
  ${props => (props.date ? `font-size: 0.9rem;` : ``)}
  ${props => (props.linethro ? `text-decoration: line-through;` : ``)}
  ${props => (props.purple ? `color: #cc99ff;` : ``)}
`;

export const ExtLink = styled.a`
  color: #f17cc5;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 1.0em;
  ${props => (props.simple ? `text-decoration: none` : ``)}
`;

export const StyledLink = styled(Link)`
  margin-left: 1.0em;
  font-family: 'Allerta', sans-serif;
  font-size: 1.2rem;
  text-decoration: underline;
  color: #000000;
  &:visited {
    color: #000000;
  }
  ${props =>
    props.current
      ? `background: linear-gradient(to right, #d16ba5, #cf6fb2, #ca73be, #c478cb, #bc7ed7, #a68fea, #8d9ef7, #72acff, #40c3ff, #00d8ff, #1febff, #5ffbf1); 
      color: #000000; 
      &:visited {color: #000000}`
      : ``}
  cursor: pointer;
`;
