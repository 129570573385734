import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  display: column;
  overflow: hidden;
`;

export const SubContainer = styled.section`
  width: 80%;
  ${props => (props.menu ? `width:100%;` : ``)}
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
`;

export const ImgWrapper = styled.div`
  max-width: 200px;
  ${props => (props.header ? `` : `max-width: 325px;`)}
  margin: auto;
  padding: 1rem;
`;
