import React from "react";
import { ImgWrapper } from "./styles/containers";
import { Title, Par, ExtLink } from "./styles/typography";
import HeaderImg from "./images/headerImg";
import CatImg from "./images/catImg";
import AboutImg from "./images/aboutImg";
import ProjectImg from "./images/projectImg";
import SiaImg from "./images/siaImg";

const Header = props => (
  <header>
    <Title>{`${props.title}`}</Title>
    <ImgWrapper header>
      {props.random && <CatImg />}
      {props.about && <AboutImg />}
      {props.projects && <ProjectImg />}
      {props.misc && <SiaImg />}
      {!props.random && !props.about && !props.projects && !props.misc && (
        <HeaderImg />
      )}
    </ImgWrapper>

    <Par date>
      <ExtLink href="https://github.com/qchenme" aria-label="github">
        Github
      </ExtLink>
      {" | "}
      <ExtLink href="https://www.linkedin.com/in/qi-c-727268131/" aria-label="linkedin">
        Linkedin
      </ExtLink>
      {" | "}
      <ExtLink href="mailto:hi@qichen.me" aria-label="email">
        Email
      </ExtLink>
    </Par>
  </header>
);

export default Header;
