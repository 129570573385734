import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { SubContainer } from "./styles/containers";
import { Par, ExtLink } from "./styles/typography";
import { Divider } from "./styles/decoration";

const ProjectList = () => (
  <StaticQuery
    query={graphql`
      query projectssQuery {
        allProjectsJson {
          edges {
            node {
              title
              description
              link
            }
          }
        }
      }
    `}
    render={data =>
      data.allProjectsJson.edges.map(item => (
        <SubContainer key={item.node.title}>
          { item.node.link === "" ? (
              <Par bold blue>{item.node.title.toUpperCase()}</Par>
            ) : (
              <Par bold blue><ExtLink href={item.node.link}>{item.node.title.toUpperCase()}</ExtLink></Par>
            )
          }
          <Par>{item.node.description}</Par>
          <Divider />
        </SubContainer>
      ))
    }
  />
);

export default ProjectList;
