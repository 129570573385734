import React from "react";
import { ImgWrapper, SubContainer } from "./styles/containers";
import { Par, ExtLink } from "./styles/typography";
import { Button } from "./styles/buttons";
import PeanutsImg from "./images/peanutsImg";

class About extends React.Component {
  state = {
    detailOpen: false,
    secondDetailOpen: false
  };

  switchClick = () => {
    const currentStatus = this.state.detailOpen;
    if (currentStatus) {
      this.setState({ detailOpen: !currentStatus, secondDetailOpen: !currentStatus });
    }
    else {
      this.setState({ detailOpen: !currentStatus });
    }
  };

  switchSecondClick = () => {
    const currentStatus = this.state.secondDetailOpen;
    this.setState({ secondDetailOpen: !currentStatus });
  };

  render() {
    return (
      <SubContainer>
        <Par>
          Code, mostly web. <br /> Design occasionally.
        </Par>
        {this.state.detailOpen ? (
          <SubContainer>
            <Button onClick={this.switchClick}>Okay, enough ↑</Button>
            <Par>(*ﾟｰﾟ)ゞ⌒☆ hi, I'm
              <ExtLink href="https://translate.google.ca/#view=home&op=translate&sl=auto&tl=en&text=%E7%90%AA">
                Qi (琪).
              </ExtLink>
            </Par>
            <Par>
              I'm a software developer based in Montreal.
            </Par>
            <br />
            {this.state.secondDetailOpen ? (
              <>
                <Button onClick={this.switchSecondClick}>Nah, fold this ↑</Button>
                <Par>
                  Here's the Peanuts comic strip on my birthday:
                </Par>
                <ImgWrapper>
                  <PeanutsImg />
                </ImgWrapper>
                <Par>
                  and if you want to know more about my cats (somehow) - their names are muffin and mochi.
                </Par>
              </>) : <Button onClick={this.switchSecondClick}>More? ↓</Button>
            }
          </SubContainer>
        ) : (
          <SubContainer>
            <Button onClick={this.switchClick}>More about me ↓</Button>
          </SubContainer>
        )}
      </SubContainer>
    );
  }
}

export default About;
