import styled from "styled-components";

export const Divider = styled.hr`
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;

  border: none;
  border-top: 1px dashed #616161;
  color: #616161;
  overflow: visible;
  text-align: center;
  height: 5px;

  &:after {
    background: #fff;
    content: '꒰◍ᐡᐤᐡ◍꒱';
    padding: 0 4px;
    position: relative;
    top: -13px;
  }
`;
