import React from "react";
import { Container } from "./styles/containers";
import { Par } from "./styles/typography";
import Header from "./header";
import Menu from "./menu";
import Hello from "./hello";
import About from "./about";
import Random from "./random";
import Misc from "./misc";
import Projects from "./projects";

const Main = props => (
  <Container>
    <Header
      title={props.title}
      misc={props.misc}
      random={props.random}
      about={props.about}
      projects={props.projects}
    />

    <Menu title={props.title} />
    {props.hello && <Hello />}
    {props.about && <About />}
    {props.random && <Random />}
    {props.misc && <Misc />}
    {props.projects && <Projects />}
    {props.notFound && <Par purple>Looks like you are in the middle of nowhere. <br /> But you found a cat. <br />ฅ^•ﻌ•^ฅ: Meow~~</Par>}
  </Container>
);

export default Main;
