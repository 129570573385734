import React from "react";
import { SubContainer } from "./styles/containers";
import { Par, ExtLink } from "./styles/typography";
import { Divider } from "./styles/decoration";

const Misc = () => (
  <SubContainer>
    <Par italic purple>♪MISCELLANEOUS♪</Par>

    <SubContainer>
      <Par bold blue>The pig?</Par>
      <Par>The pig is my signature pig.</Par>
      <Divider />

      <Par bold blue>"Sleep is all I believe in"</Par>
      <Par>Originally from the movie "Le Feu Follet", <br /> this sentence contains
        every essence of my philosophy of life.</Par>
    </SubContainer>
  </SubContainer>
);

export default Misc;
