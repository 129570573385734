import React from "react";
import { Par } from './styles/typography';
import { ImgWrapper, SubContainer } from "./styles/containers";
import HelloImg from "./images/helloImg";

const Hello = () => (
  <SubContainer>
    <Par italic purple>( ᐛ ) Nice to meet you</Par>
    <ImgWrapper>
      <HelloImg />
    </ImgWrapper>
  </SubContainer>
);

export default Hello;
