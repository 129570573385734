import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const ProjectImg = () => (
  <StaticQuery
    query={graphql`
      query projectImageQuery {
        file(relativePath: { eq: "project.png" }) {
          childImageSharp {
            fluid(maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.file.childImageSharp.fluid} />}
  />
);

export default ProjectImg;
